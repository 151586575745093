import React, { useState, useEffect } from "react";
import { Card, Button, Col, Form, Table, Tooltip, OverlayTrigger, Alert, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import TimeLine from "../../components/TimeLine";
import ptBR from 'date-fns/locale/pt-BR';
import { IrPortal } from "../../util/funcoes";
import apiAxios from "../../api/apiAxios";
import Menu from "../../components/Menu";
import {exibirMensagemAguarde, exibirMensagemErro} from "../../util/util";

export default function EnviarConector() {
    const [situacaoId, setSituacaoId] = useState("0");
    const [situacaoDesc, setSituacaoDesc] = useState("");
    const [situacoes, setSituacoes] = useState([]);
    const [dataInicial, setDataInicial] = useState(null);
    const [dataFinal, setDataFinal] = useState(null);
    const [nome, setNome] = useState("");
    const [registros, setRegistros] = useState([]);
    const [rga, setRga] = useState("");
    const [exibirEnvio, setExibirEnvio] = useState(false);
    const [processando, setProcessando] = useState(false);
    const [registrosEnviados, setRegistrosEnviados] = useState([]);
    
    useEffect(() => {
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario === null || usuario === undefined || usuario === "99999999999") {
            IrPortal();
        }
        
        apiAxios.get("/api/Conector/situacoes").then(({ data }) =>
        {
            if (Array.isArray(data) && data.length > 0)
            {
                data = data.filter(obj => obj.descricao !== 'Diploma digital revogado');
            }

            setSituacoes(data);
        });
    }, []);

    const selecionarItem = (index, value) => {
        const updatedRegistros = [...registros];
        updatedRegistros[index].selecionado = value;
        setRegistros(updatedRegistros);
    }

    const getRegistro = async () => {
        exibirMensagemAguarde("Por favor aguarde!", "Consultando dados...");
        const obj = {
            situacaoId: `${situacaoId}`,
            dataInicial: "",
            dataFinal: "",
            nome: nome,
            rga: rga
        }
        if (dataInicial) {
            obj.dataInicial = `${dataInicial.toISOString().split("T")[0]}`;
        }
        if (dataFinal) {
            obj.dataFinal = `${dataFinal.toISOString().split("T")[0]}`;
        }
        console.log(obj);
        try {
            const { data } = await apiAxios.post("/api/Conector/registros", JSON.stringify(obj), {
                headers: { 'content-type': 'application/json' },
            });
            console.log("data", data);
            setRegistros(data);
            Swal.close()
        }
        catch (ex) {
            Swal.close()
            exibirMensagemErro('Erro ao buscar registros', ex);
        }
     }

    const toggleItens = (value) => {
        const updatedRegistros = registros.map(item => ({ ...item, selecionado: value }));
        setRegistros(updatedRegistros);
    }

    const atualizarStatus = async () => {
        exibirMensagemAguarde("Por favor aguarde!", "Buscando atualizações dos registros...");

        try {
            const selecionados = registros.filter(x => x.selecionado);
            if (selecionados.length === 0) {
                Swal.close()
                Swal.fire({ icon: 'warning', title: 'Nenhum registro selecionado, Verifique' });
                return;
            }

            const itens = selecionados.map(i => ({ registroId: i.id }))
            await apiAxios.put("/api/Conector/atualizar-situacao", JSON.stringify(itens), {
                headers: { 'content-type': 'application/json' },
            });
            Swal.close()
            Swal.fire('Situação atualizada com sucesso', "", 'success').then(result => {
                window.location.reload();
            })
        } catch (ex) {
            Swal.close()
            Swal.fire('Erro ao atualizar situação', `Tente novamente. Se erro persistir entre em contato com o administrador do sistema.`, 'error');
            console.error("try", ex);
        }
    }

    const enviarDiplomaDigital = async () => {
        try
        {
            setRegistrosEnviados([]);
            const selecionados = registros.filter(x => x.selecionado);
            if (selecionados.length === 0) {
                Swal.fire({ icon: 'warning', title: 'Nenhum registro selecionado, Verifique' });
                return;
            }
            setProcessando(true);
            setExibirEnvio(true);
            for (let i = 0; i < selecionados.length; i++)
            {
                const envio = selecionados[i];                    
                await apiAxios.post(`/api/Conector/enviar/xml-diploma/${envio.id}`).then((response) => {
                    envio.success = true;
                    console.log("response", response);
                    setRegistrosEnviados(prevState => [...prevState, envio]);
                }).catch(ex => {
                    envio.success = false;
                    envio.error = ex?.request?.response ?? ex;
                    setRegistrosEnviados(prevState => [...prevState, envio]);
                    console.error(ex);
                    console.error(ex?.request?.response);
                });
            }
            setProcessando(false);
        }
        catch (ex)
        {
            setProcessando(false);
            Swal.close()
            Swal.fire('Erro ao enviar XML', `Tente novamente. Se erro persistir entre em contato com o administrador do sistema.`, 'error');
            console.log("try", ex);
        }
    }

    const enviarDocumentacaoAcademica = async () => {
        try
        {
            const selecionados = registros.filter(x => x.selecionado);
            setRegistrosEnviados([]);
            if (selecionados.length === 0) {
                Swal.fire({ icon: 'warning', title: 'Nenhum registro selecionado, Verifique' });
                return;
            }
            setProcessando(true);
            setExibirEnvio(true);
            for (let i = 0; i < selecionados.length; i++)
            {
                const envio = selecionados[i];
                const resultValidacao = await apiAxios.get(`/api/Documentacao/valida-envio/${envio.id}`);
                console.log("resultValidacao", resultValidacao);
                if (!resultValidacao.data.envioOk) {
                    envio.success = false;
                    envio.error = resultValidacao.data.mensagem;
                    setRegistrosEnviados(prevState => [...prevState, envio]);
                } else {
                    const { data } = await apiAxios.get(`/api/Conector/json/doc-academica/${envio.id}`);
                    const urlConector = `${process.env.REACT_APP_CONECTOR}documents`;
                    const documentType = "4";
                    let form = new FormData();
                    form.append("documentType", documentType);
                    form.append("documentData", JSON.stringify(data));
                    form.append("documentFile", "");
                    const acesso = await apiAxios.get("/api/Conector/access");
                    const config = {
                        headers: { Authorization: `Bearer ${acesso.data}` }
                    };
                    await apiAxios.post(urlConector, form, config).then(async (response) => {
                        let obj = {};
                        obj.documentoUID = `${response.data.documentId}`;
                        obj.documentType = documentType;
                        obj.registroUID = `${envio.id}`;
                        obj.situacaoUID = "";
                        obj.yourNumber = "";
                        try {
                            obj.yourNumber = data.meta.yourNumber;
                        } catch (ex) {
                            console.log("yourNumber", ex);
                        }
                        await apiAxios.post("/api/Conector/salvar-envio", JSON.stringify(obj), {
                            headers: { 'content-type': 'application/json' },
                        }).catch(error => {
                            console.error("error", error);
                        });
                        envio.success = true;
                        setRegistrosEnviados(prevState => [...prevState, envio]);
                    }).catch(async ex => {
                        const erroJson = {
                            message: "",
                            TipoXml: "Documentação Acadêmica",
                            validationErrors: []
                        };
                        if (ex.response?.data?.error?.details) {
                            for (let i = 0; i < ex.response.data.error.details.length; i++) {
                                erroJson.message += ex.response.data.error.details[i].message + "\n";
                            }
                        }
                        if (erroJson.message.length === 0 && ex.response?.data?.error?.message) {
                            erroJson.message = ex.response.data.error.message;
                        }
                        await apiAxios.post(`/api/Conector/salvar-erro/${envio.id}/9`, JSON.stringify(erroJson), {
                            headers: { 'content-type': 'application/json' },
                        }).catch(error => {
                            console.error("error", error);
                        });
                        envio.success = false;
                        envio.error = erroJson.message;
                        setRegistrosEnviados(prevState => [...prevState, envio]);
                    });
                }
            }
            setProcessando(false);
        }
        catch (ex)
        {
            setProcessando(false);
            Swal.close()
            Swal.fire('Erro ao enviar XML', `Tente novamente. Se erro persistir entre em contato com o administrador do sistema.`, 'error');
            console.log("try", ex);
        }
    }

    const renderTooltipReexecutarProcesso = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Utilize essa opção quando: Desejar refazer o processo de envio. Assinaturas deverão ser realizadas novamente.
        </Tooltip>
    );

    const renderTooltipReiniciarProcesso = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Utilize essa opção quando: Houver erros no envio do XML e deseja reiniciar o processamento.
        </Tooltip>
    );

    const exibirOpcoesDeControles = () => {
        const menuControles = [];
        menuControles.push(<></>);
        if (registros.length > 0) {
            if (situacaoDesc === "Aguardando envio do XML da documentação acadêmica") {
                menuControles.push(<Button
                    variant="primary"
                    type="button"
                    style={{ marginLeft: 15 }}
                    onClick={enviarDocumentacaoAcademica}>
                    <i className="fas fa-file-import"></i> Enviar XML da Documentação Acadêmica
                </Button>)
            }

            if (situacaoDesc === "XML da documentação acadêmica enviado" ||
                situacaoDesc === "XML do diploma digital enviado" ||
                situacaoDesc === "Aguardando assinatura do XML da documentação acadêmica" ||
                situacaoDesc === 'Aguardando assinatura do diploma digital' ||
                situacaoDesc === "XML da documentação acadêmica assinado" ||
                situacaoId === "0" ||
                situacaoDesc === "Aguardando revogação do diploma digital no MEC" ||
                situacaoDesc === "XML do diploma digital assinado") {
                menuControles.push(
                    <Button
                        variant="primary"
                        type="button"
                        style={{ marginLeft: 15 }}
                        onClick={atualizarStatus}>
                        <i className="fas fa-sync"></i> Buscar atualização de status
                    </Button>
                )
            }

            if (situacaoDesc === "Aguardando envio do XML do diploma digital") {
                menuControles.push(
                    <Button
                        variant="primary"
                        type="button"
                        style={{ marginLeft: 15 }}
                        onClick={enviarDiplomaDigital}>
                        <i className="fas fa-file-import"></i> Enviar XML do Diploma Digital
                    </Button>
                )
            }
        }

        return menuControles.map((menu, index) => (
            <React.Fragment key={index}>
                {menu}
            </React.Fragment>
        ))
    }

    return (
        <React.Fragment>
            {exibirEnvio ? <React.Fragment>
                    {processando ? <React.Fragment>
                <Card.Title>
                    <h3>Por favor aguarde! Enviando XML(s)...</h3>
                </Card.Title>
                <div className={"d-flex justify-content-center"} style={{ "marginTop": "50px" }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </React.Fragment> : <React.Fragment>
                        <Card.Title>
                            <h3>Processo realizado</h3>
                        </Card.Title>
                        <div className="form-row">
                            <Button
                                variant="primary"
                                type="button"
                                onClick={() => {
                                    setRegistros([]);
                                    setRegistrosEnviados([]);
                                    setExibirEnvio(false);
                                    setProcessando(false);
                                }}>
                                <i className="fas fa-undo"></i> Fechar
                            </Button>
                        </div>
                    </React.Fragment>}
                <hr/>
                {Array.isArray(registrosEnviados) && registrosEnviados.length > 0 &&
                    <React.Fragment>
                        <Table striped bordered hover responsive>
                            <thead>
                            <tr>
                                <th>Nº Registro</th>
                                <th>Diplomado</th>
                                <th>Detalhes</th>
                            </tr>
                            </thead>
                            <tbody>
                            {registrosEnviados.map((reg, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{reg.id}</td>
                                        <td>{reg.estudante}</td>
                                        <td>
                                            {reg.success ?
                                                <>
                                                    <i className="fas fa-check-circle fa-lg"
                                                       style={{color: "green"}}></i>{" "}
                                                    XML enviado com sucesso
                                                </>
                                                : <>
                                                <i className="fas fa-times fa-lg" style={{color: "red"}}></i>{" "}
                                                Erro ao enviar XML: {reg.error}
                                                </>}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </React.Fragment>}
            </React.Fragment> : <React.Fragment> 
            <Menu
                title="Gerenciar Envios dos arquivos XML"
                menus={[
                    { path: "/cae", desc: "Página Inicial - CAE" },
                ]} />
            <Card.Title>
                <h3>Gerenciar Envios dos arquivos XML</h3>
            </Card.Title>
                 <div className="row d-flex flex-row">
                     <div className="col-12 col-md-6">
                         <Alert variant="success" style={{ "borderLeft": "4px solid" }} className="bg-white">
                               Fluxo do Processo:<br />
                             1) Fazer o upload da documentação do discente em formato PDF/A no menu: "Documentação Acadêmica";<br />
                             2) Gerar o registro do diploma no menu "Consultar Registros";<br />
                             3) Realizar os envios e assinaturas do XML (conforme próximo quadro);<br />
                             4) Gerar a representação visual do diploma;<br />
                         </Alert>
                     </div>
                     <Col sm={12} md={6}>
                         <Alert variant="success" style={{ "borderLeft": "4px solid" }} className="bg-white">
                             Fluxo do Envio do XML:<br />
                             1) realizar o envio do XML da Documentação Acadêmica;<br />
                             2) assinar o XML da Documentação Acadêmica no sistema web <a target="_blank" href={process.env.REACT_APP_RAPSING}>RAPSign</a>;<br />
                             3) realizar a sincronização da situação do XML da Documentação Acadêmica;<br />
                             4) realizar o envio do XML do Diploma Digital;<br />
                             5) assinar o XML da Diploma Digital no sistema web <a target="_blank" href={process.env.REACT_APP_RAPSING}>RAPSign</a>;<br />
                             6) realizar a sincronização da situação do XML do Diploma Digital;<br />
                         </Alert>
                     </Col>
                 </div>
                 <Card>
                     <Card.Body>
                         <TimeLine itens={[{ texto: "Aguardando envio do XML" },
                             { texto: "XML enviado" },
                             { texto: "Aguardando assinatura do XML" },
                             { texto: "XML assinado" },
                             { texto: "XML válido" }]} />
                         <hr />
                         <div className="d-flex flex-wrap">
                             <div className="mb-3 col-auto">
                                 <Form.Label><strong>Data registro inicial</strong></Form.Label>
                                 <DatePicker
                                     locale={ptBR}
                                     selected={dataInicial}
                                     onChange={(data) => {
                                         setDataInicial(data)
                                     }}
                                     className="form-control"
                                     dateFormat="dd/MM/yyyy"
                                 />
                             </div>
                             <div className="mb-3 col-auto">
                                 <Form.Label><strong>Data registro final</strong></Form.Label>
                                 <DatePicker
                                     locale={ptBR}
                                     selected={dataFinal}
                                     onChange={(date) => {
                                            setDataFinal(date)
                                     }}
                                     className="form-control"
                                     dateFormat="dd/MM/yyyy"
                                 />
                             </div>
                             <div className="mb-3 col-auto">
                                 <Form.Label><strong>Situação</strong></Form.Label>
                                 <select className="form-select" value={situacaoId} onChange={(e) => {
                                     setSituacaoId(e.target.value);
                                     setRegistros([]);
                                     setSituacaoDesc(e.target.options[e.target.selectedIndex].innerHTML)
                                 }}>
                                     <option value="0">***** Selecione uma situação *****</option>
                                     {Array.isArray(situacoes) && situacoes.length > 0 && situacoes.map(r => <option key={r.situacaoId.toString()} value={r.situacaoId.toString()}>{r.descricao}</option>)}
                                 </select>
                             </div>
                         </div>
                         <br />
                         <div className="form-row">
                             <Col sm={12} md={6}>
                                 <div className="mb-3">
                                     <Form.Label><strong>Nome</strong></Form.Label>
                                     <input type="text" id="nome" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
                                 </div>
                             </Col>
                             <Col sm={12} md={6}>
                                 <div className="mb-3">
                                     <Form.Label><strong>RGA</strong></Form.Label>
                                     <input type="number" id="rga" className="form-control" value={rga} onChange={(e) => setRga(e.target.value )} />
                                 </div>
                             </Col>
                         </div>
                         <br />
                         <div className="form-row">
                             <Button
                                 variant="primary"
                                 type="button"
                                 onClick={getRegistro}>
                                 <i className="fas fa-search"></i> Localizar Dados
                             </Button>
                             {exibirOpcoesDeControles()}
                         </div>
                         {Array.isArray(registros) && registros.length > 0 &&
                             <React.Fragment>
                                 <br />
                                 <div className="form-row">
                                     <Button
                                         variant="primary"
                                         type="button"
                                         onClick={() => toggleItens(true)}>
                                         <i className="far fa-check-square"></i> Marcos todos registros
                                     </Button>
                                     <Button
                                         variant="primary"
                                         type="button"
                                         style={{marginLeft: 15}}
                                         onClick={() => toggleItens(false)}>
                                         <i className="far fa-square"></i> Desmarcar todos registros
                                     </Button>
                                 </div>
                             </React.Fragment>}
                         <hr />
                         {Array.isArray(registros) && registros.length > 0 &&
                             <React.Fragment>
                                 <Table striped bordered hover responsive>
                                     <thead>
                                     <tr>
                                         <th>Nº Registro</th>
                                         <th>Diplomado</th>
                                         <th>RGA</th>
                                         <th>Data Registro</th>
                                         <th>Situação</th>
                                         <th></th>
                                     </tr>
                                     </thead>
                                     <tbody>
                                     {registros.map((reg, index) => {
                                         return (
                                             <tr key={index}>
                                                 <td><Form.Check type="checkbox" value={reg.id} checked={reg.selecionado} label={reg.id} onClick={(e) => { selecionarItem(index, e.target.checked) }} /></td>
                                                 <td>{reg.estudante}</td>
                                                 <td>{reg.rga}</td>
                                                 <td>{reg.dataRegistro}</td>
                                                 <td>{reg.situacao}</td>
                                                 <td>
                                                     <OverlayTrigger
                                                         placement="top"
                                                         delay={{ show: 250, hide: 400 }}
                                                         overlay={(props) => (
                                                             <Tooltip id="button-tooltip" {...props}>
                                                                 Detalhes
                                                             </Tooltip>)}>
                                                         <Link
                                                             className="btn btn-primary btn-list"
                                                             to={`/Conector/Detalhes/${reg.id}`}>
                                                             <i className="fas fa-eye"></i>
                                                         </Link>
                                                     </OverlayTrigger>
                                                 </td>
                                             </tr>
                                         )
                                     })}
                                     </tbody>
                                 </Table>
                                 </React.Fragment>}
                     </Card.Body>
                 </Card>
            </React.Fragment>}
        </React.Fragment>
    );
}