import * as React from 'react'
import { Card, Breadcrumb, Alert } from 'react-bootstrap'
import CancelarRegistroForm from '../../components/Area/CAE/CancelarRegistroForm'
import { loadRegistro } from '../../store/thunks'
import { connect } from 'react-redux'
import { IrPortal } from "../../util/funcoes";
import Swal from 'sweetalert2'
import * as api from './../../api/api'
import apiAxios from './../../api/apiAxios';
import apiURLs from './../../api/apiURLs';
import { Link } from 'react-router-dom'

class ReativarRegistro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            registroId: this.props.match.params.registroId,
            assinado: true,
            suspenso: true,
            motivos:[]
        }
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');        
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(loadRegistro(this.state.registroId))
        this.getSituacaoRegistro();
        this.getMotivos();
    }

    handleRedirect(){
        window.location.href = '/digital/registros'
    }    
    
    getMotivos () {
        try {            
            let url = "/api/registro/cancelaMotivos";            
            apiAxios
                .get(url)
                .then((result) => {               
                    this.setState({ motivos: result.data })
                })
                .catch((ex) => {
                    console.log(ex)
                }) 
        } catch (e) {       
            Swal.close()      
            Swal.fire({
                icon: 'error',
                title: 'Erro ao buscar motivos de anulação de registro!',
                html: 'Se o erro persistir entre em contato com o administrador do sistema.'
            })
        }
    }

    getSituacaoRegistro () {
        try {            
            let url = apiURLs.GET_REGISTRO_SITUACAO + this.state.registroId;            
            apiAxios
                .get(url)
                .then((result) => {
                    if (!result.data.suspenso)
                        this.handleRedirect()
                    else
                        this.setState({ assinado: result.data.assinado, suspenso: result.data.suspenso })
                })
                .catch((ex) => {
                    console.log(ex)
                }) 
        } catch (e) {       
            Swal.close()      
            Swal.fire({
                icon: 'error',
                title: 'Erro ao buscar situação de registro!',
                html: 'Se o erro persistir entre em contato com o administrador do sistema.'
            })
        }
    }

    handleSubmit (registroId,
        motivoId,
        justificativa,
        responsavel,
        responsavelDocumento) {
        try {
            Swal.fire({
                showCancelButton: false, // There won't be any cancel button
                showConfirmButton: false, // There won't be any confirm button
                title: 'Por favor aguarde!',
                html: 'Desfazendo suspensão de registro...',
                allowOutsideClick: false,
                willOpen: () => {
                    Swal.showLoading()
                },
            })
            const dadosCancelamento = JSON.stringify({
                registroId: Number(registroId),
                motivoId: Number(motivoId),
                justificativa,
                responsavel,
                responsavelDocumento,
            })            
            api.reativarRegistro(dadosCancelamento, registroId).then((resp) => {                
                if (resp.status !== 200) {              
                    Swal.close()      
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro ao desfazer suspensão de registro!',
                        html: 'Verifique se os campos obrigatórios foram preenchidos e tente novamente. Se este erro persistir entre em contato com o administrador do sistema.'
                    })
                } else {
                    Swal.close()            
                    Swal.fire({
                    icon: 'success',
                    title: 'Registro reativado com sucesso! Redirecionando...',
                    showConfirmButton: false,
                    timer: 10000,
                    }).then(this.handleRedirect())             
                }
            })
        } catch (e) {       
            Swal.close()      
            Swal.fire({
                icon: 'error',
                title: 'Erro ao desfazer suspensão de registro!',
                html: 'Verifique se os campos obrigatórios foram preenchidos e tente novamente. Se este erro persistir entre em contato com o administrador do sistema.'
            })
        }
    }

    render() {
        return (
            <>
                <Breadcrumb className="arr-right">
                    <li className="breadcrumb-item">
                        <Link
                            to="/cae">
                            Página Inicial - CAE
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link
                            to="/registros">
                            Consulta de Registros
                        </Link>
                    </li>
                    <Breadcrumb.Item active>
                        Desfazer Suspensão de Registro
                    </Breadcrumb.Item>
                </Breadcrumb>
                
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h2>Desfazer Suspensão de Registro</h2>
                            <small>
                                Preencha as informações abaixo para continuar
                                com a reativação do registro de diploma.
                            </small>
                        </Card.Title>                        
                        <br></br>
                        <CancelarRegistroForm
                                registroId={this.state.registroId}
                                estudante={this.props.registro?.diplomado?.nome ?? ""}
                                dataRegistro={this.props.registro?.livroRegistro?.dataRegistroDiploma ?? ""}
                                responsavelRegistro={this.props.registro?.livroRegistro?.responsavelRegistro ?? ""}
                                handleSubmit={this.handleSubmit}
                                motivos={this.state.motivos}
                                revogarOuSuspender={false}
                                suspenso={this.state.suspenso}
                        />
                    </Card.Body>
                </Card>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    registros: state.registros.registros,
    registro: state.registros.registro,
    loading: state.registros.loading,
    error: state.registros.error,
})

export default connect(mapStateToProps)(ReativarRegistro)
