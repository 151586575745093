import React from "react";
import { Breadcrumb, Card, Table, Col, Form, Button } from 'react-bootstrap';
import axios from "axios";
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { IrPortal } from "../../util/funcoes";
import apiAxios from "../../api/apiAxios";
import { Link } from "react-router-dom";

export class DetalheConector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            registroId: this.props.match.params.registroId,
            nome: "",
            curso: "",
            rga: "",
            situacao: "",
            documentos: [],
            erros: []
        }
                
        const usuario = sessionStorage.getItem('@ufmt.diploma.app/ResponsavelRegistroCPF');
        console.log("usuario", usuario);
        if (usuario == null || usuario == undefined || usuario == "99999999999") {
            IrPortal();
        }
    }
    	
    atualizarStatus = async () => {
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Buscando atualizações do registro...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });

        try {
            const itens = [];
            itens.push({ registroId: parseInt(this.state.registroId) })
            console.log(itens);
            await apiAxios.put("/api/Conector/atualizar-situacao", JSON.stringify(itens), {
                headers: { 'content-type': 'application/json' },
            });

            Swal.close()            
            Swal.fire('Situação atualizada com sucesso', "", 'success').then(result => {
                window.location.reload();
            })
        } catch (ex) {
            Swal.close()
            Swal.fire('Erro ao atualizar situação', `Tente novamente. Se erro persistir entre em contato com o administrador do sistema.`, 'error');
            console.log("try", ex);
        }
    }

    downloadPDF = () => {
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Buscando dados do diploma...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        apiAxios({
            url: `/api/Conector/download-diploma/${this.state.registroId}`,
            method: 'GET',
            responseType: 'blob',
        }).then(response => {
            Swal.close();
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
            const link = document.createElement('a');
            let fileNameServidor;
            try {
                fileNameServidor = response.headers["content-disposition"].split("filename=")[1].split(";")
            } catch (ex) {
                fileNameServidor = undefined;
            }
            const fileName = (fileNameServidor == undefined ? `Diploma_${this.state.registroId}.pdf` : fileNameServidor[0]);
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
        }).catch(ex => {
            Swal.close();
            Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex.Mensagem, 'error');
        });
    }

    downloadXML = (tipoDesc) => {
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Buscando dados do diploma...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        try {
            let tipo = "";
            if (tipoDesc == "Diploma Digital") {
                tipo = "xml-diploma";
            } else if (tipoDesc == "Documentação acadêmica") {
                tipo = "xml-doc-academica";
            }
            if (tipo) {
                apiAxios({
                    url: `/api/Conector/download/${tipo}/${this.state.registroId}`,
                    method: 'GET',
                    responseType: 'blob',
                }).then(response => {
                    Swal.close();
                    let fileNameServidor;
                    try {
                        fileNameServidor = response.headers["content-disposition"].split("filename=")[1].split(";")
                    } catch (ex) {
                        console.log(ex);
                        fileNameServidor = undefined;
                    }
                    const fileName = (fileNameServidor == undefined ? "XML.xml" : fileNameServidor[0]);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    link.click();
                }).catch(ex => {
                    Swal.close();
                    Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex.Mensagem, 'error');
                });
            } else {
                Swal.close();
                Swal.fire('Oops...', 'XML não encontrado', 'warning');
            }
        } catch (ex) {
            Swal.close();
            Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex, 'error');
        }
    }

    downloadPreviaXMLSemAssinaturas = (tipoDesc) => {
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Buscando dados do diploma...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        try {
            let tipo = "";
            if (tipoDesc == "Diploma Digital") {
                tipo = "xml-diploma";
            } else if (tipoDesc == "Documentação acadêmica") {
                tipo = "xml-doc-academica";
            }
            if (tipo) {
                apiAxios({
                    url: `/api/Conector/previa-download/${tipo}/${this.state.registroId}`,
                    method: 'GET',
                    responseType: 'blob',
                }).then(response => {
                    Swal.close();
                    console.log(response)
                    let fileNameServidor;
                    try {
                        fileNameServidor = response.headers["content-disposition"].split("filename=")[1].split(";")
                    } catch (ex) {
                        console.log(ex);
                        fileNameServidor = undefined;
                    }
                    const fileName = (fileNameServidor == undefined ? "XML.xml" : fileNameServidor[0]);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    link.click();
                }).catch(ex => {
                    Swal.close();
                    Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex.Mensagem, 'error');
                });
            } else {
                Swal.close();
                Swal.fire('Oops...', 'XML não encontrado', 'warning');
            }
        } catch (ex) {
            Swal.close();
            Swal.fire('Oops...', 'Erro ao baixar o arquivo :' + ex, 'error');
        }
    }

    componentDidMount() {
        Swal.fire({
            showCancelButton: false, // There won't be any cancel button
            showConfirmButton: false, // There won't be any confirm button
            title: 'Por favor aguarde!',
            html: 'Carregando dados...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });

        //let url =`api/Conector/info/${this.state.registroId}`;
        let url =`/api/Conector/info/${this.state.registroId}`;
        
         apiAxios.get(url).then(({ data }) => {
            Swal.close();
             console.log("data", data);
             this.setState({
                nome: data.diplomado,
                curso: data.curso,
                rga: data.rga
            });
             if (data.situacao != null && data.situacao != undefined) {
                this.setState({ situacao: data.situacao });
             }
             if (data.documentos != null && data.documentos != undefined) {
                this.setState({ documentos: data.documentos });
             } else {
                 if (!data.situacao) {
                     this.setState({ situacao: "Aguardando envio do XML da documentação acadêmica" });
                 }
             }
             if (data.erros != null && data.erros != undefined) {
                this.setState({ erros: data.erros });
             }
        }).catch(ex => {
            console.log(ex);
            Swal.fire('Erro ao carregar dados', `Tente novamente. Se erro persistir entre em contato com o administrador do sistema.`, 'error');
            Swal.close()
        })
    }

    render() {
        return (
            <>
                <Breadcrumb className="arr-right">
                    <li className="breadcrumb-item">
                        <Link to="/cae">
                            Página Inicial - CAE
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/Conector/Enviar">
                            Gerenciar Envios dos arquivos XML
                        </Link>
                    </li>
                    <Breadcrumb.Item active>
                        Detalhes do Registro
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Card className="my-3">
                    <Card.Body>
                        <Card.Title>
                            Detalhes do Registro {this.state.registroId}
                        </Card.Title>
                        <hr />
                        <Form.Row>
                            <Col sm={12} md={4}>
                                <Form.Group>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control plaintext readOnly defaultValue={this.state.nome} />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={4}>
                                <Form.Group>
                                    <Form.Label>RGA</Form.Label>
                                    <Form.Control plaintext readOnly defaultValue={this.state.rga} />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={4}>
                                <Form.Group>
                                    <Form.Label>Curso</Form.Label>
                                    <Form.Control plaintext readOnly defaultValue={this.state.curso} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={12} md={6}>
                                <Form.Group>
                                    <Form.Label>Situação</Form.Label>
                                    <Form.Control plaintext readOnly defaultValue={this.state.situacao} />
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        {this.state.situacao != "Diploma digital válido" && this.state.situacao != "Diploma digital suspenso" && this.state.situacao != "Diploma digital revogado" ?
                        <>
                        <div className="d-flex gap-3">
                            <Button
                                variant="primary"
                                type="button"
                                onClick={() => { this.downloadPreviaXMLSemAssinaturas('Diploma Digital') }}>
                                <i className="fas fa-file-download me-1"></i>Prévia XML Diplomado (Sem Assinaturas)
                            </Button>
                            <Button
                                variant="primary"
                                type="button"
                                onClick={() => { this.downloadPreviaXMLSemAssinaturas('Documentação acadêmica') }}>
                                <i className="fas fa-file-download me-1"></i>Prévia XML Documentação Acadêmica (Sem Assinaturas)
                            </Button>
                        </div>
                            <br></br></>
                            : <></>
                        }
                        {this.state.situacao == "Diploma digital válido" ?
                            <Form.Row>
                                <Col sm={12} md={6}>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        style={{ marginLeft: 15 }}
                                        onClick={() => {this.downloadPDF()}}>
                                        <i className="fas fa-file-download"></i> Representação Visual PDF
                                    </Button>
                                </Col>
                            </Form.Row>
                            : (this.state.documentos.length > 0 ?
                                <Form.Row>
                                    <Col sm={12} md={6}>
                                        <Button
                                            variant="success"
                                            type="button"
                                            style={{ marginLeft: 15 }}
                                            onClick={this.atualizarStatus}>
                                            <i className="fas fa-sync"></i> Buscar atualização de status
                                        </Button>
                                    </Col>
                                </Form.Row>
                                : <></>)}
                    </Card.Body>
                </Card>
                <br />
                {this.state.documentos.length > 0 ?
                    <>
                        {this.state.documentos.map((documento, index) => {
                            return (
                                <>
                                    <Card key={index}>
                                        <Card.Body>
                                            <Form.Row>
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>XML</Form.Label>
                                                        <Form.Control plaintext readOnly defaultValue={documento.tipo} />
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Situação</Form.Label>
                                                        <Form.Control plaintext readOnly defaultValue={documento.id < 0 ? "Não enviado" : documento.situacaoConector} />
                                                        {documento.situacaoConector == "Documento Válido" && 
                                                            (documento.tipo == "Documentação acadêmica" || documento.tipo == "Diploma Digital") ?
                                                            <>
                                                                <Button
                                                                    variant="primary"
                                                                    type="button"
                                                                    style={{ marginLeft: 15 }}
                                                                    onClick={() => this.downloadXML(documento.tipo)}>
                                                                    <i className="fas fa-file-download"></i> Download XML
                                                                </Button>
                                                            </>
                                                            : <></>}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            {documento.log && documento.log.length > 0 ?
                                                <Form.Row>
                                                    <Table striped bordered hover responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Situação Anterior</th>
                                                                <th>Situação Atual</th>
                                                                <th>Data</th>
                                                                <th>Informações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {documento.log.map((log, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{log.situacaoAnterior}</td>
                                                                        <td>{log.situacaoAtual}</td>
                                                                        <td>{format(new Date(Date.parse(log.dataHora)), 'dd/MM/yyyy HH:mm:ss')}</td>
                                                                        <td>{log.info}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </Form.Row>
                                                : <></>}
                                            {documento.assinaturas && documento.assinaturas.length > 0 ? 
                                                <>
                                                <br />
                                                <br />
                                                <Form.Row>
                                                        <Form.Label>Assinaturas</Form.Label>
                                                    <Table striped bordered hover responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Responsável</th>
                                                                <th>Tipo</th>
                                                                <th>Situação</th>
                                                                <th>Tag</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {documento.assinaturas.map((assinatura, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{assinatura.responsavel}</td>
                                                                        <td>{assinatura.arquivamento ? "Arquivamento " : ""}{assinatura.tipo}</td>
                                                                        <td>{assinatura.situacao}</td>
                                                                        <td>{assinatura.tag}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </Form.Row>
                                                </> 
                                                : <></>}
                                        </Card.Body>
                                    </Card>
                                    <br />
                                </>
                            )
                        })}
                    </>
                    : <></>}
                    <br />
                {this.state.erros.length > 0 ?
                    <Card>
                        <Card.Body>
                            <Form.Row>
                                <Col sm={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>Erro(s):</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Data</th>
                                            <th>XML</th>
                                            <th>Erro</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.erros.map((erro, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{format(new Date(Date.parse(erro.dataHora)), 'dd/MM/yyyy HH:mm:ss')}</td>
                                                    <td>{erro.xml}</td>
                                                    <td>{erro.erro}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Form.Row>
                        </Card.Body>
                    </Card>
                    : <></>}
            </>
        )
    }
}

export default DetalheConector;